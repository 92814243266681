body {
    display: block;
    margin: 0px !important;
}


/* START :: VARIABLES */

@import '../../variables/variables.scss';

/* END :: VARIABLES */


/* START :: TAILWIND CSS */

@import '../tailwind-output/wireframe.scss';

/* END :: TAILWIND CSS */