:root {
    --theme-default-color: rgba(249, 120, 23);
    --theme-default-color-disabled: rgb(223, 145, 85);
    --theme-gray-color: rgb(175, 175, 175);
    --theme-gray-color-disabled: rgb(216, 215, 215);
    --theme-success-color: rgb(19, 133, 57);
    --theme-success-color-disabled: rgb(107, 190, 135);
    --theme-danger-color: rgb(185, 26, 26);
    --theme-danger-color-disabled: rgb(182, 118, 118);
    --theme-warning-color: rgb(202, 128, 17);
    --theme-warning-color-disabled: rgb(175, 150, 111);
    --theme-info-color: rgb(2, 137, 170);
    --theme-info-color-disabled: rgb(108, 163, 177);
    --theme-primary-color: rgb(11, 59, 216);
    --theme-primary-color-disabled: rgb(76, 93, 146);
}